import { createStore } from "vuex";
import axios from "axios";

export default createStore({
  state: {
    API_URL:"https://jelentkezesirendszerbackend20230323081840.azurewebsites.net/",
    //API_URL: "https://localhost:7259/",
    key: "1d93f8a843855a0bf41d68584b51bba33b1361a760d5c3fdd2f2578b9023f2cc",
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {},
});
